<template>
  <div class="mb-3">
    <table class="table">
      <thead class="table-light">
        <tr>
          <th scope="col">{{ $t("STATUS") }}</th>
          <th scope="col">{{ $t("PROGRESS") }}</th>
          <th scope="col">{{ $t("SCENARIO") }}</th>
          <th scope="col">
            {{ $t("OFF_SITE_TABLE_HEADER_FORM") }}
            <i
              class="bi bi-info-circle-fill ms-1 custom-tooltip tooltip-middle-width"
            >
              <span class="tooltiptext">{{
                $t("OFF_SITE_TABLE_HEADER_FORM_TOOLTIP")
              }}</span>
            </i>
          </th>
          <th scope="col">
            {{ $t("CONTACT_SPECIFICS") }}
            <i
              class="bi bi-info-circle-fill ms-1 custom-tooltip tooltip-middle-width"
            >
              <span
                class="tooltiptext"
                v-html="$t('CONTACT_SPECIFICS_TOOLTIP')"
              />
            </i>
          </th>
          <th
            scope="col"
            class="border-0 bg-none"
            v-if="isMarketOrAgencyUser"
          ></th>
          <th
            scope="col"
            class="border-0 bg-none"
            v-if="isMarketOrAgencyUser"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="config in configs"
          :key="config.id"
          :class="{ preparedWave: config.preparedWave }"
        >
          <!-- status icon -->
          <td class="custom-tooltip">
            <span
              class="tooltiptext"
              data-ctooltip-position="top"
              v-if="config.status"
              >{{ $t(config.status?.toUpperCase()) }}
            </span>
            <i class="bi bi-gear" v-if="config.status === 'inprogress'"></i>
            <i
              class="bi bi-clock-history"
              v-if="config.status === 'pending'"
            ></i>
            <i class="bi bi-check2" v-if="config.status === 'approved'"></i>
          </td>
          <!-- progress in % -->
          <td :class="{ 'text-muted': isAdminUser }">
            <span>{{ config.progress }}%</span>
          </td>

          <!-- Contact way as select -->
          <td>
            <label class="custom-tooltip">
              <select
                class="form-select border-0"
                @input="updateContactWay($event.target.value, config)"
                :disabled="configurationParametersReadonly(config)"
                :title="isAdminUser ? $t('ONLY_FOR_MARKET_USER') : ''"
              >
                <option
                  v-if="
                    !config.contactWayId ||
                    contactWays.some((v) => v.id === config.contactWayId) ===
                      false
                  "
                  selected
                  disabled
                >
                  {{ $t("PLEASE_SELECT") }}
                </option>
                <option
                  v-for="contactWay in contactWays"
                  :key="contactWay.id"
                  :data-id="contactWay.id"
                  :value="contactWay.id"
                  :selected="
                    config.contactWayId && config.contactWayId === contactWay.id
                  "
                >
                  {{ contactWay.title }}
                </option>
              </select>
              <span class="tooltiptext" data-ctooltip-position="top">{{
                config.contactWay
                  ? config.contactWay.title
                  : $t("PLEASE_SELECT")
              }}</span>
            </label>
          </td>

          <!-- Other contact way required -->
          <td>
            <label class="custom-tooltip">
              <select
                class="form-select border-0"
                @input="updateOtherContactWay($event.target.value, config)"
                :ref="`other_contact_way_${config.id}`"
                :disabled="
                  configurationParametersReadonly(config) || blockFields(config)
                "
                :title="isAdminUser ? $t('ONLY_FOR_MARKET_USER') : ''"
              >
                <option v-if="!config.otherContactWay" selected disabled>
                  {{ $t("PLEASE_SELECT") }}
                </option>
                <option
                  value="phone"
                  :selected="config.otherContactWay === 'phone'"
                >
                  Phone
                </option>
                <option
                  value="email"
                  :selected="config.otherContactWay === 'email'"
                >
                  Email
                </option>
                <option
                  value="phone_and_email"
                  :selected="config.otherContactWay === 'phone_and_email'"
                >
                  Phone & Email
                </option>
              </select>
              <span class="tooltiptext" data-ctooltip-position="top">{{
                $t(
                  config.otherContactWay
                    ? config.otherContactWay.toUpperCase()
                    : "PHONE"
                )
              }}</span>
            </label>
          </td>

          <!-- Contact specifics as input -->
          <td>
            <input
              type="text"
              class="form-control border-0"
              placeholder="Please fill in..."
              v-model="config.contactSpecifics"
              @change="updateContactSpecifics($event.target.value, config)"
              :disabled="configurationFieldsReadonly(config)"
              :ref="`offsite_contact_specifics_${config.id}`"
              :title="isAdminUser ? $t('ONLY_FOR_MARKET_USER') : ''"
            />
          </td>

          <!-- start, resume, summary Buttons (Market) -->
          <td class="border-0 text-end ps-3" v-if="isMarketOrAgencyUser">
            <OnsiteButton
              v-if="!config.status && !activeWaveReadonly"
              :text="'START'"
              :primary="true"
              :disabled="
                blockStart(config) ||
                configurationLockService.checkLocked(config?.id)
              "
              :runConfig="getStartConfigFunction(config)"
            ></OnsiteButton>

            <OnsiteButton
              v-if="config.status === 'inprogress' && !activeWaveReadonly"
              :text="'CONFIGURATION'"
              :disabled="
                blockReview(config) ||
                configurationLockService.checkLocked(config?.id)
              "
              :runConfig="getRunConfigFunction(config)"
            ></OnsiteButton>

            <OnsiteButton
              v-if="
                config.status === 'pending' ||
                config.status === 'approved' ||
                activeWaveReadonly
              "
              :text="'VIEW'"
              :disabled="blockReview(config)"
              :runConfig="getViewConfigFunction(config)"
            ></OnsiteButton>
          </td>

          <!-- start review, resume review Buttons (Admin) -->
          <td
            style="width: 10rem"
            class="border-0 text-end ps-3"
            v-if="
              isAdminUser &&
              (config.status === 'pending' || config.status === 'approved')
            "
          >
            <OnsiteButton
              v-if="config.status === 'pending' && !activeWaveReadonly"
              :text="'REVIEW'"
              :primary="true"
              :disabled="
                blockReview(config) ||
                configurationLockService.checkLocked(config?.id)
              "
              :runConfig="getRunConfigFunction(config)"
            ></OnsiteButton>

            <OnsiteButton
              v-if="config.status === 'approved' || activeWaveReadonly"
              :text="'VIEW'"
              :runConfig="getViewConfigFunction(config)"
              :disabled="blockReview(config)"
            ></OnsiteButton>
          </td>

          <!-- Reset amd Delete config icons -->
          <td
            v-if="!activeWaveReadonly"
            class="border-0 ps-3"
            data-jp="reset-on-site"
          >
            <div
              class="custom-tooltip tooltip-middle-width"
              v-if="config.status === 'approved'"
            >
              <i class="bi bi-lock-fill" style="color: green" />
              <span class="tooltiptext" style="top: 100%; left: -1000%">{{
                $t("APPROVED_CONFIGURATION")
              }}</span>
            </div>
            <div v-else>
              <div
                v-if="
                  configurationLockService.checkLocked(config?.id) &&
                  ((isAdminUser &&
                    (config.status === 'pending' ||
                      config.status === 'approved')) ||
                    (isMarketOrAgencyUser && config.status === 'inprogress'))
                "
              >
                <div class="custom-tooltip tooltip-middle-width">
                  <i class="bi bi-exclamation-octagon-fill" />
                  <span class="tooltiptext" style="top: 100%; left: -1000%">{{
                    $t("LOCKED_CONFIGURATION")
                  }}</span>
                </div>
              </div>
              <div v-else>
                <div v-if="isMarketOrAgencyUser">
                  <div
                    class="custom-tooltip tooltip-middle-width"
                    v-if="config.status === 'inprogress'"
                  >
                    <i
                      class="bi bi-arrow-repeat"
                      @click="showResetModal(config)"
                    />
                    <span class="tooltiptext" style="top: 100%; left: -1000%">{{
                      $t("RESET_CONFIGURATION")
                    }}</span>
                  </div>
                  <div
                    class="custom-tooltip tooltip-middle-width"
                    v-if="!config.status"
                  >
                    <i class="bi bi-trash" @click="showDeleteModal(config)" />
                    <span class="tooltiptext" style="top: 100%; left: -1000%">{{
                      $t("DELETE_CONFIGURATION")
                    }}</span>
                  </div>
                  <div
                    class="custom-tooltip tooltip-middle-width"
                    v-if="config.status === 'pending'"
                  >
                    <i class="bi bi-lock-fill" style="color: gold" />
                    <span class="tooltiptext" style="top: 100%; left: -1000%">{{
                      $t("SUBMITTED_CONFIGURATION")
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td class="border-0">
            <div
              v-show="showDuplicateWindow(config)"
              class="w-60 rounded border border-primary"
              role="alert"
            >
              Duplicate config error, update not executed
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <delete-configuration-modal
    v-if="delete_modal"
    v-on:hideDeleteModal="hideDeleteModal"
    v-on:deleteConfig="deleteConfig"
  />

  <reset-configuration-modal
    v-if="reset_modal"
    v-on:hideResetModal="hideResetModal"
    v-on:resetConfiguration="resetConfiguration"
  />
</template>

<script>
import { useStore } from "vuex";
import DeleteConfigurationModal from "@/components/modals/DeleteConfigurationModal";
import ResetConfigurationModal from "@/components/modals/ResetConfigurtionModal";
import OnsiteButton from "../on-site/OnSiteButton.vue";
import configurationLockService from "@/services/configurationLockService";
export default {
  name: "OffSiteTable",
  components: {
    ResetConfigurationModal,
    DeleteConfigurationModal,
    OnsiteButton,
  },
  data() {
    return {
      delete_modal: false,
      config_id: null,
      reset_modal: false,
      configurationLockService: configurationLockService,
    };
  },
  async setup() {
    const store = useStore();
    await store.dispatch("configurations/loadOffSiteConfigurations");
    return {};
  },
  created() {
    configurationLockService.connectOffSiteTable(this);
  },
  computed: {
    configs() {
      return this.$store.getters["configurations/getOffSiteConfigurations"];
    },
    isAdminUser() {
      return this.$store.getters["users/isAdminUser"];
    },
    isMarketOrAgencyUser() {
      return this.$store.getters["users/isMarketOrAgencyUser"];
    },
    contactWays() {
      return this.$store.getters["configurations/getOffSiteContactWays"];
    },
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
  },
  methods: {
    showDuplicateWindow(config) {
      return (
        this.$store.getters[
          "configurations/getDuplicateErrorConfigurationId"
        ] === config.id
      );
    },
    blockFields(config) {
      return (
        this.contactWays
          .find((v) => v.id === config.contactWayId)
          ?.title.toLowerCase()
          .includes("contact form") === false
      );
    },
    blockStart(config) {
      return (
        !config.contactWayId ||
        this.contactWays.some((v) => v.id === config.contactWayId) === false ||
        !config.otherContactWay
      );
    },
    blockReview(config) {
      return (
        !config.contactWayId ||
        this.contactWays.some((v) => v.id === config.contactWayId) === false
      );
    },
    getStartConfigFunction(config) {
      return async () => {
        //Lock configuration
        configurationLockService.lockConfiguration(config.id);

        // start configuration
        await this.$store.dispatch(
          "configurations/startConfiguration",
          config.id
        );

        // reset duplicate configuration window
        this.$store.commit(
          "configurations/setDuplicateErrorConfigurationId",
          -1
        );

        this.$router.push(
          `/projects/${this.$route.params.projectGuid}/waves/${this.$route.params.waveGuid}/configurations/${config.id}`
        );
      };
    },
    getRunConfigFunction(config) {
      return async () => {
        //Lock configuration
        configurationLockService.lockConfiguration(config.id);

        // reset duplicate configuration window
        this.$store.commit(
          "configurations/setDuplicateErrorConfigurationId",
          -1
        );

        this.$router.push(
          `/projects/${this.$route.params.projectGuid}/waves/${this.$route.params.waveGuid}/configurations/${config.id}`
        );
      };
    },
    getViewConfigFunction(config) {
      return async () => {
        // reset duplicate configuration window
        this.$store.commit(
          "configurations/setDuplicateErrorConfigurationId",
          -1
        );

        this.$router.push(
          `/projects/${this.$route.params.projectGuid}/waves/${this.$route.params.waveGuid}/configurations/${config.id}`
        );
      };
    },
    async updateContactWay(contactWayId, config) {
      let getCurrentContactwayId = config.contactWayId;
      let currentOtherContactWay = config.otherContactWay;
      let currentContactSpecifics = config.contactSpecifics;
      config.contactWayId = parseInt(contactWayId);
      let contactWay = this.contactWays
        .find((v) => v.id === config.contactWayId)
        .title.toLowerCase();
      if (contactWay.includes("call")) {
        config.otherContactWay = "phone";
        config.contactSpecifics = null;
      }
      if (contactWay.includes("e-mail")) {
        config.otherContactWay = "email";
        config.contactSpecifics = null;
      }
      await this.$store.dispatch("configurations/setupConfiguration", config);
      if (
        this.$store.getters[
          "configurations/getDuplicateErrorConfigurationId"
        ] === config.id
      ) {
        config.contactWayId = getCurrentContactwayId;
        config.otherContactWay = currentOtherContactWay;
        config.contactSpecifics = currentContactSpecifics;
        this.$forceUpdate();
      }
    },
    async updateOtherContactWay(otherContactWay, config) {
      let currentOtherContactWay = config.otherContactWay;
      config.otherContactWay = otherContactWay;
      await this.$store.dispatch("configurations/setupConfiguration", config);
      if (
        this.$store.getters[
          "configurations/getDuplicateErrorConfigurationId"
        ] === config.id
      ) {
        config.otherContactWay = currentOtherContactWay;
        this.$forceUpdate();
      }
    },
    async updateContactSpecifics(contactSpecifics, config) {
      let currentContactSpecifics = config.contactSpecifics;
      config.contactSpecifics = contactSpecifics;
      await this.$store.dispatch("configurations/updateConfiguration", config);
      if (
        this.$store.getters[
          "configurations/getDuplicateErrorConfigurationId"
        ] === config.id
      ) {
        config.contactSpecifics = currentContactSpecifics;
        this.$forceUpdate();
      }
    },
    async deleteConfig() {
      await this.$store.dispatch(
        "configurations/deleteConfiguration",
        this.config_id
      );
      this.$store.commit("configurations/setDuplicateErrorConfigurationId", -1);
      this.delete_modal = false;
    },
    showDeleteModal(config) {
      this.config_id = config.id;
      this.delete_modal = true;
    },
    hideDeleteModal() {
      this.config_id = null;
      this.delete_modal = false;
    },
    showResetModal(config) {
      this.config_id = config.id;
      this.reset_modal = true;
    },
    hideResetModal() {
      this.reset_modal = false;
      this.config_id = null;
    },
    configurationParametersReadonly(config) {
      return (
        config.status ||
        this.$store.getters["users/isAdminUser"] ||
        this.$store.getters["waves/getActiveWaveReadonly"]
      );
    },
    configurationFieldsReadonly(config) {
      return (
        this.$store.getters["waves/getActiveWaveReadonly"] ||
        config.status === "approved" ||
        (config.status === "pending" &&
          this.$store.getters["users/isAdminUser"] == false) ||
        (config.status !== "pending" &&
          this.$store.getters["users/isMarketUser"] == false)
      );
    },
    async resetConfiguration() {
      await this.$store.dispatch(
        "configurations/resetConfiguration",
        this.config_id
      );
      this.$store.commit("configurations/setDuplicateErrorConfigurationId", -1);
      await this.hideResetModal();
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  vertical-align: middle;
  text-align: center;
}
</style>
